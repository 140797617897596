import React, { Component } from 'react'
import { Link } from "gatsby"
import Header from "../components/Header"
import ProjectHeader from "../components/ProjectHeader"
import Footer from "../components/Footer"
import '../css/project.css'
import Fade from 'react-reveal/Fade';


class Project extends Component {
  render() {
    return (
      <div> 
    <Header />
    <Fade>
    <div className="ProjectContainer">
      <ProjectHeader
        title="Figma Community"
        description="Product Design Internship"
        timeline="Summer 2020"
        image={require('../images/figma.png')}
      />
      <h6>Background</h6>
      <h2><a href="https://www.figma.com/home">Figma</a> is a cloud-based design tool dedicated to making design on the web accessible to everyone. I worked on the <a href="https://www.figma.com/community">Community</a> team. </h2>
      <p>
        In October 2019, <a className ="BodyLink" href = "https://www.figma.com/blog/introducing-figma-community/">Figma announced Community</a>, an online space for designers and developers to come together to create and share open-source designs and plugins. During my time at Figma, I was able to work on projects that ranged from small to large, including defining the future of Community. I had an incredible experience working at Figma and learned so much from everyone there.
      </p>

      <p>
        Currently, the full Community experience is rolling out to 100% of users.
      </p>
      
      <h2 className="ComingSoon">Please reach out if you’d like to learn more about my work.</h2>
      <Link className="BackHome" to="/">Back Home</Link>
    </div>
    <Footer />
    </Fade>
  </div>
    )
  }
}

export default Project;